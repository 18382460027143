import Styled from 'styled-components';
import { baseMixin, ctaButtonMixin } from '../mixins';

const Wrapper = Styled.div`
  ${baseMixin};
  ${ctaButtonMixin};

  hr {
    margin: 24px 0;
  }

  
`;

export { Wrapper };
